import request from "@/config/serve.js";
import { MAXSIZE } from "@/common/constant/constant.js";

// 商务审核列表查询
export function getReviewList(params) {
  // return request("get", "/honorAppReviewInfo/info/list", {
  return request("get", "/promoterApp/list", {
    ...params,
  });
}
// 查询详情
export function queryGeneralDiff(params) {
  return request("get", "/app/queryGeneralDiff", params);
}
//商务确认审核
export function appInfoAudit(data) {
  // return request("post", "/honorAppReviewInfo/info/audit", data);
  return request("post", "/promoterApp/audit", data);
}
//批量审核
export function appInfoAuditBatch(data) {
  return request("post", "/honorAppReviewInfo/info/batchAudit", data);
}
// 查询分类
export function getCategoryList(params) {
  return request("get", "/cmsCategory/list/hierarchy", {
    ...params,
  });
}
// 查询标签
export function getLabelList(data) {
  return request("post", "/ozLabel/list", {
    page: 1,
    size: MAXSIZE,
    isDeleted: false,
    ...data,
  });
}
//更新-应用信息
export function updateInfo(data) {
  return request("post", "/infoAudit/updateInfo", data);
}
//更新-应用版本信息
export function updateInfoRelease(data) {
  return request("post", "/infoAudit/updateInfoRelease", data);
}
// 查询审核人员列表,type:页面（1信息审核列表，3预约审核列表）
export function getAuditUsers(params) {
  return request("get", "/app/queryAuditUsers", { type: 1, ...params }, false);
}
