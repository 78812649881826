<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
    label-width="130px"
    label-suffix="："
  >
    <el-form-item label="审核结果" prop="status">
      <el-radio-group
        v-model="formData.status"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="3">通过</el-radio>
        <el-radio :label="2">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- <template v-if="formData.reviewResult == 1">
      <el-form-item>
        <div>确认以下项目是否全部审核完成</div>
        <el-checkbox-group
          v-model="checkedItems"
          @change="handleCheckedItemsChange"
        >
          <el-checkbox
            v-for="item in checkedItemList"
            :key="item.key"
            :label="item.key"
          >
            {{ item.value }}
          </el-checkbox>
        </el-checkbox-group>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全部勾选</el-checkbox
        >
      </el-form-item>
    </template> -->
    <template>
      <el-form-item
        label="审核意见"
        prop="remark"
        :rules="[{ required: formData.status == 2, message: '请输入审核意见' }]"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 6, maxRows: 6 }"
          show-word-limit
          maxlength="100"
          v-model="formData.remark"
          suffix-icon="el-icon-date"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="文件上传">
        <UploadFile
          type="other"
          class="ml10"
          ref="uploadRef"
          @success="uploadSuccess"
          v-model="formData.accessFilePath"
        ></UploadFile>
        <div class="ml10" v-show="formData.fileName">
          <span>{{ formData.fileName }}</span>
          <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
        </div>
      </el-form-item> -->
    </template>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import storage from "@/common/utils/storage";
export default {
  components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {},
  data() {
    return {
      formData: {
        status: "",
        remark: "",
        // accessFileId: "",
        // accessFilePath: "",
        // fileName: "",
      },
      rules: {
        status: [{ required: true, message: "请选择审核结果" }],
        remark: [{ required: true, message: "请填写审核意见" }],
      },
      checkedItemList: [
        { key: "1", value: "基础应用信息" },
        { key: "2", value: "开发者行为" },
        { key: "3", value: "客服热线" },
      ],
      checkedItems: [],
      checkAll: false,
      isIndeterminate: false,
    };
  },
  computed: {
    allItemKeys() {
      return this.checkedItemList.map((i) => i.key);
    },
  },
  methods: {
    uploadSuccess(file) {
      this.formData.accessFilePath = file.accessPath;
      this.formData.accessFileId = file.fileId;
      this.formData.fileName = file.fileName;
    },
    handleRemoveFile() {
      this.formData.accessFilePath = "";
      this.formData.accessFileId = "";
      this.formData.fileName = "";
    },
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        // let uploadValid = true;
        // if (this.$refs.uploadRef) {
        //   // 存在文件上传时，校验文件是否已上传完成
        //   uploadValid = this.$refs.uploadRef.getValidate();
        // }
        // if (valid && uploadValid) {
        //   // if (this.formData.reviewResult == 1 && !this.checkAll) {
        //   //   this.$message.error("请确认勾选所有需审核项目");
        //   //   return false;
        //   // }
        //   // this.$emit("submit", this.formData);
        // }
        let obj = {
          ...this.formData,
          appId: this.detailData.appId,
          resolveUserId: storage.getUserInfo().userId,
        };
        if (valid) {
          this.$emit("submit", obj);
        }
      });
    },
    handleCheckAllChange(val) {
      this.checkedItems = val ? this.allItemKeys : [];
      this.isIndeterminate = false;
    },
    handleCheckedItemsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkedItemList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checkedItemList.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.auditModel {
  width: 60%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .el-checkbox {
    display: block;
  }
}
</style>
