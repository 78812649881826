import request from "@/config/serve.js";
import { MAXSIZE, ZHID } from "@/common/constant/constant.js";

// 查询
export function getApkList(data) {
  return request("post", "/app/appInfoList", data);
}
//生成创建app SecretKey
export function createAppInfo(data) {
  return request("post", "/app/createAppInfo", data);
}
//保存应用信息和版本信息(普通)
export function saveUpdateReleaseInfo(data) {
  return request("post", "/app/saveUpdateReleaseInfo", data);
}
//提交应用信息和版本信息(普通)
export function submitReleaseInfo(data) {
  return request("post", "/app/submitReleaseInfo", data);
}
//应用预约-保存(预约)
export function appReservedSave(data) {
  return request("post", "/appReserved/appReservedSave", data);
}
//应用预约-提交(预约)
export function appReservedSubmit(data) {
  return request("post", "/appReserved/appReservedSubmit", data);
}
//编辑保存内测版本信息(内测)
export function saveTestInfo(data) {
  return request("post", "/appTest/saveTestInfo", data);
}
//提交内测应用版本信息(内测)
export function submitTestInfo(data) {
  return request("post", "/appTest/submitTestInfo", data);
}
// 查询应用信息-普通
export function queryGeneral(params) {
  return request("get", "/app/queryGeneral", params);
}
// 查询应用信息-预约
export function getReservedById(params) {
  return request("get", "/app/getReservedById", params);
}
// 查询应用信息-内测
export function queryTest(params) {
  return request("get", "/app/queryTest", params);
}
//上架/下架
export function changeStatus(params) {
  return request("get", "/app/updateReleaseStatus", params);
}
// 查询历史版本列表
export function getHistoryList(data) {
  return request("post", "/app/appInfoHistory", data);
}
// 发布类型是否能转换查询
export function updateReleaseType(params) {
  return request("get", "/app/updateReleaseType", params);
}
// 查询语言列表
export function getLanguageList(params) {
  return request("get", "/language/all", params);
}
// 回退历史版本
export function appBack(params) {
  return request("get", "/app/appBack", params);
}
// 查询分类
export function getCategoryList(params) {
  return request("get", "/cmsCategory/list/hierarchy", {
    languageId: ZHID,
    deleted: false,
    ...params,
  });
}
// 查询标签
export function getLabelList(data) {
  return request("get", "/ozLabel/all", {
    page: 1,
    size: MAXSIZE,
    isDeleted: false,
    languageId: ZHID,
    ...data,
  });
}
// 查询国家、地区
export function getAreaList(params) {
  return request("get", "/app/selectCountry", params);
}
// 应用-设置ratio
export function editRatio(params) {
  return request("post", "/app/editRatio", params);
}
// 应用-查看国家详情
export function lookCountry(params) {
  return request("post", "/app/editRatio", params);
}
